import { Link } from 'gatsby'
import * as React from 'react'
import { btn } from '../../styles/components/buttons.module.scss'

const WaveRaveHourBtn = () => {
  return (
    <Link to="/services/3-hour-wave-rave-djd-nightime-party-boat-charter" className={btn}>View Package</Link>
  )
}

export default WaveRaveHourBtn