import * as React from "react";
import { serviceInfoFeatures } from "../../styles/components/blocks.module.scss";
import IconTimer from "../icons/IconTimer";
import IconUserPlus from "../icons/IconUserPlus";
import IconCalendarCheck from "../icons/IconCalendarCheck";
import IconClockFive from "../icons/IconClockFive";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import IconCamera from "../icons/IconCamera";
import IconBottle from "../icons/IconBottle";
import IconWater from "../icons/IconWater";
import IconMusic from "../icons/IconMusic";
import IconBeer from "../icons/IconBeer";
import IconDjDeck from "../icons/IconDjDeck";

const SunsetPageInfo = () => {
  return (
    <div>
      <div className={serviceInfoFeatures}>
        <span>What's Included</span>
        <span>
          <IconTimer /> 8 Hours
        </span>
        <span>
          <IconUserPlus /> 12 Guests
        </span>
        <span style={{ display: "flex",gap: "5px", alignItems: "center" }}>
          <img
            style={{ margin: "0", }}
            src="https://cdn4.iconfinder.com/data/icons/o021-sea-cruise-and-travel-outline/64/hat_captain_cap_travel-512.png"
            width="17px"
            alt="Captain"
          />{" "}
        Captain
        </span>
        {/* <span style={{ display: "flex",gap: "5px", alignItems: "center" }}>
          <img
           style={{ margin: "0", }}
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyGr3sckOuwu74lxr1jNcsRVBMCJXjpThhr4FS1dGdHg&s"
            width="17px"
            alt="Captain"
          />{" "}
          Boat Fuel
        </span>{" "} */}
        <span style={{ display: "flex",gap: "5px", alignItems: "center" }}>
          <img
            style={{ margin: "0", }}
            src="https://cdn.iconscout.com/icon/premium/png-256-thumb/cooler-box-2213720-1854110.png?f=webp&w=256"
            width="17px"
            alt="Captain"
          />{" "}
          Ice & Coolers
        </span>{" "}
        {/* <span style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ marginRight: "5px" }}
            src="https://static.thenounproject.com/png/4583471-200.png"
            width="17px"
            alt="Captain"
          />{" "}
         Drinking Water
        </span> */}
        {/* <span><IconClockFive /> 1PM or Earlier</span> */}
      </div>
      <hr className={hideBlockSM} />
      <div className={serviceInfoFeatures}>
        <span>Add-Ons Available</span>
        <span>
          <IconCamera /> Photographer
        </span>
        <span>
          <IconBottle /> Bottle Girl/Boy
        </span>
        <span>
          <IconDjDeck /> DJ
        </span>
      </div>
      <hr className={hideBlockSM} />
      <div className={serviceInfoFeatures}>
        <span>What You Bring</span>
        {/* <span>
          <IconWater /> Towels
        </span> */}
        <span>
          <IconMusic /> Music Playlist
        </span>
        <span>
          <IconBeer /> BYOB
        </span>
      </div>
    </div>
  );
};

export default SunsetPageInfo;
