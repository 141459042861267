import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../templates/LayoutService";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import privatePartyBoatCruisesImg from "../images/banners/group-of-friends-on-party-boat.jpg";
import privatePartyBoatCharter8HourImg from "../images/banners/party-boating-captain-and-beautiful-girl-on-boat-in-tampa-bay.jpg";
import privatePartyBoatCharter3WaveHourImg from "../images/services/header-wave-rave-dj-boat-ride.jpg";
import privatePartyBoatCharter6HourImg from "../images/banners/tampa-bay-boating-adventures-boat-with-logo.jpg";
import privatePartyBoatCharter4HourImg from "../images/banners/friends-on-party-boat-with-flags.jpg";
import privatePartyBoatCharterExclusiveIslandOvernight from "../images/banners/bridge-near-tampa-bay.jpg";
import privatePartyBoatCharterSunsetImg from "../images/banners/gorgeous-sunset-on-tampa-bay-coast.jpg";
import EightHourBtn from "../components/buttons/EightHourBtn";
import SixHourBtn from "../components/buttons/SixHourBtn";
import FourHourBtn from "../components/buttons/FourHourBtn";
import ExclusiveIslandOvernightBtn from "../components/buttons/ExclusiveIslandOvernightBtn";
import SunsetHourBtn from "../components/buttons/SunsetHourBtn";
import BuyEightHourBtn from "../components/buttons/BuyEightHourBtn";
import EightHourInfo from "../components/blocks/EightHourInfo";
import BuySixHourBtn from "../components/buttons/BuySixHourBtn";
import SixHourInfo from "../components/blocks/SixHourInfo";
import BuyFourHourBtn from "../components/buttons/BuyFourHourBtn";
import BuyExclusiveIslandOvernightBtn from "../components/buttons/BuyExclusiveIslandOvernightBtn";
import FourHourInfo from "../components/blocks/FourHourInfo";
import BuySunsetBtn from "../components/buttons/BuySunsetBtn";
import SunsetInfo from "../components/blocks/SunsetInfo";
import ServicesList from "../components/blocks/ServicesList";
import { imgBox } from "../styles/components/boxes.module.scss";
import { btn } from "../styles/components/buttons.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  serviceInfoContainer,
  serviceInfoBlock,
  serviceInfoBtns,
  pageCalloutBlock,
  pageContentMedia,
} from "../styles/components/blocks.module.scss";
import { hideBlockSM } from "../styles/components/helpers.module.scss";
import "../styles/components/titles.module.scss";
import "../styles/components/header.module.scss";
import "../styles/components/content.module.scss";
import WaveRaveBuyBtn from "../components/buttons/WaveRaveBuyBtn";
import WaveRaveHourBtn from "../components/buttons/WaveRaveHourBtn";
import WaveRavePageinfo from "../components/blocks/WaveRave";

const ServicesPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>
          Customizable Private Party Boat Charter Services in Tampa Bay, Florida
          | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="We offer 4, 6, or 8-hour private party boat charters to gorgeous Florida destinations in the Tampa Bay, FL area. Book your private party boat adventure today!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Friends jumping off a party boat into the water near Tampa Bay, FL."
                  src="../images/banners/friends-jumping-off-party-boat-into-water.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  Private Party Boat Services in Tampa Bay
                </h1>
                <span className={bannerBlockTitleSm}>
                  Explore the <br className={hideBlockSM} />
                  Tampa Coast
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <ServicesList />
            <div className={pageContentBlock}>
              <h2>
                Drink, laugh, and party it up on one of our private party boat
                cruises.
              </h2>
              <h3>
                We offer a variety of cruises including an 8-hour charter,
                6-hour charter, 4-hour charter, as well as a sunset cruise.
              </h3>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="Group of friends on a party boat near Tampa Bay, FL."
                    src={privatePartyBoatCruisesImg}
                  />
                </div>
              </div>
              <p>
                Are you looking to explore the beautiful waters of the Tampa
                Area with your friends? Would you like to party on a private
                island while soaking up the sun? Do you want to watch the sun
                set over the water in a way you never have before? If so, it’s
                time to book a private party boat charter with Tampa Bay Boating
                Adventures. We offer 8-hour, 6-hour, and 4-hour private party
                boat charters that can take you and your friends to a variety of
                places around the Tampa Bay area including Beer Can Island, the
                Tampa Riverwalk, exclusive sandbars, and more! We also offer a
                sunset cruise that will allow you to watch the sunset in the
                most beautiful way. No matter which charter you choose, you can
                bring your own alcohol, so you can really get the party started
                and turn it into your own personal booze cruise!
              </p>
              <hr />
              <div className={serviceInfoContainer}>
                <div className={serviceInfoBlock}>
                  <h2>8-Hour Private Party Boat Charter Service</h2>
                  <div className={serviceInfoBtns}>
                    <BuyEightHourBtn />
                    <div className={` ${btn}`} style={{ marginLeft: "10px" }}>
                      <Link to="tel:8137428178" className="link call-style">
                        Call (813) 742-8178
                      </Link>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <EightHourBtn />
                    </div>
                  
                  </div>
                  <EightHourInfo />
                </div>
              </div>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="Party boat captain and beautiful girl on a boat in Tampa Bay, FL."
                    src={privatePartyBoatCharter8HourImg}
                  />
                </div>
              </div>
              <p>
                Our 8-hour party boat charter is perfect for
                bachelor/bachelorette parties, birthdays, or if you just want to
                spend an entire day out on the water with your friends. When you
                book this private charter, you can take full control of the day.
                We can take you to{" "}
                <strong>
                  beautiful private islands that feature white sand beaches
                </strong>{" "}
                and sandbars where you can party in waist-high water. Or, we can
                even dock up at Tampa Riverwalk to turn this cruise into a
                nautical bar crawl. We want your trip to be an unforgettable
                one, so we’ll go pretty much anywhere you want.
              </p>
              <p>
                <EightHourBtn />
              </p>
              <hr />
              <div className={serviceInfoContainer}>
                <div className={serviceInfoBlock}>
                  <h2>3-HOUR NIGHTTIME PARTY BOAT CHARTER</h2>
                  <div className={serviceInfoBtns}>
                    <WaveRaveBuyBtn />
                    <div className={` ${btn}`} style={{ marginLeft: "10px" }}>
                      <Link to="tel:8137428178" className="link call-style">
                        Call (813) 742-8178
                      </Link>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                    <WaveRaveHourBtn />
                    </div>
                   
                  </div>
                  <WaveRavePageinfo />
                </div>
              </div>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="Party boat captain and beautiful girl on a boat in Tampa Bay, FL."
                    src={privatePartyBoatCharter3WaveHourImg}
                  />
                </div>
              </div>
              <p>
                Bring your crew and dance all night long on our 3-hour Wave Rave
                party boat experience. We’ll provide a professional DJ who will
                mix beats all night long, curating the vibe while your crew of
                up to 12 soak in the city lights and groove to your heart’s
                content. If you’re in town for a bachelor/bachelorette party,
                birthday party, vacation, or you just want to experience the
                best that Tampa Bay has to offer - look no further. This is our
                favorite year-round boating experience in Tampa Bay and we’re
                excited to be able to share this with you too!
              </p>
              <p>
                <WaveRaveHourBtn />
              </p>
              <hr />
              <div className={serviceInfoContainer}>
                <div className={serviceInfoBlock}>
                  <h2>6-Hour Private Party Boat Charter Service</h2>
                  <div className={serviceInfoBtns}>
                    <BuySixHourBtn />
                    <div className={` ${btn}`} style={{ marginLeft: "10px" }}>
                      <Link to="tel:8137428178" className="link call-style">
                        Call (813) 742-8178
                      </Link>
                    </div>
                   <div style={{ marginLeft: "10px" }}>
                   <SixHourBtn />
                   </div>
                  </div>
                  <SixHourInfo />
                </div>
              </div>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="Tampa Bay Boating Adventures boat with logo near Tampa, FL."
                    src={privatePartyBoatCharter6HourImg}
                  />
                </div>
              </div>
              <p>
                Our 6-hour private party boat charter can take you to all of the
                same places as our 8-hour trip. While the voyage won’t be quite
                as long, you’ll still have plenty of time to make the most of
                your day. With your favorite music blasting and ice-cold drinks
                in your cooler, you’ll be cruising the Tampa Bay waters in
                style!
              </p>
              <p>
                <SixHourBtn />
              </p>
              <div className={pageCalloutBlock}>
                <p>
                  Our party boat captains will recommend the perfect spot
                  depending on your goals!
                </p>
              </div>
              <hr />
              <div className={serviceInfoContainer}>
                <div className={serviceInfoBlock}>
                  <h2>4-Hour Private Party Boat Charter Service</h2>
                  <div className={serviceInfoBtns}>
                    <BuyFourHourBtn />
                    <div className={` ${btn}`} style={{ marginLeft: "10px" }}>
                      <Link to="tel:8137428178" className="link call-style">
                        Call (813) 742-8178
                      </Link>
                    </div>
                   <div style={{ marginLeft: "10px" }}>
                   <FourHourBtn />
                   </div>
                  </div>
                  <FourHourInfo />
                </div>
              </div>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="Friends on a party boat with flags raised near Tampa Bay, FL."
                    src={privatePartyBoatCharter4HourImg}
                  />
                </div>
              </div>
              <p>
                This charter is perfect if you only have a shorter amount of
                time to spend out on the water. You’ll still be able to soak up
                the sun, hit the islands, and drink to your heart’s content. You
                can schedule our 4-hour private party boat charter in the
                morning or in the afternoon.
              </p>
              <p>
                <FourHourBtn />
              </p>
              <hr />
              <div className={serviceInfoContainer}>
                <div className={serviceInfoBlock}>
                  <h2>Sunset Party Boat Charter Service</h2>
                  <div className={serviceInfoBtns}>
                    <BuySunsetBtn />
                    <div className={` ${btn}`} style={{ marginLeft: "10px" }}>
                      <Link to="tel:8137428178" className="link call-style">
                        Call (813) 742-8178
                      </Link>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <SunsetHourBtn />
                    </div>
                   
                  </div>
                  <SunsetInfo />
                </div>
              </div>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="Gorgeous sunset on Tampa Bay, FL coast."
                    src={privatePartyBoatCharterSunsetImg}
                  />
                </div>
              </div>
              <p>
                What better way to spend an evening than out on the water
                catching a breathtaking sunset? That’s exactly what this charter
                is for! Sip some drinks and take in the gorgeous views of Tampa
                Bay from a unique perspective. Our captains know all the best
                spots to watch the sunset, and we’ll take you to the perfect
                location depending on the day to ensure you get the best view
                possible!
              </p>
              <p>
                <SunsetHourBtn />
              </p>
              <div className={pageCalloutBlock}>
                <p>
                  All of our charters include complimentary water and snacks,
                  and we’ll also provide you with towels!
                </p>
              </div>
              <hr />
              <div className={serviceInfoContainer}>
                <div className={serviceInfoBlock}>
                  <h2>Exclusive Island Overnight Party</h2>
                  <div className={serviceInfoBtns}>
                    <BuyExclusiveIslandOvernightBtn />
                    <div className={` ${btn}`} style={{ marginLeft: "10px" }}>
                      <Link to="tel:8137428178" className="link call-style">
                        Call (813) 742-8178
                      </Link>
                    </div>
                   <div style={{ marginLeft: "10px" }}>
                   <ExclusiveIslandOvernightBtn />
                   </div>
                  </div>
                  <FourHourInfo />
                </div>
              </div>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <img
                    alt="bridge near tampa bay"
                    src={privatePartyBoatCharterExclusiveIslandOvernight}
                  />
                </div>
              </div>
              <p>
                Looking for an unforgettable party experience in the Tampa Bay
                area? If so, you’ll want to schedule our exclusive island
                overnight party excursion. This is a one-of-a-kind adventure
                that promises to be the experience of a lifetime!
              </p>
              <p>
                <ExclusiveIslandOvernightBtn />
              </p>
              <div className={pageCalloutBlock}>
                <p>
                  This island is great to fish off of, so bring some poles if
                  you’re looking to do some fishing!
                </p>
              </div>
              <hr />
              <h2>
                Fill out our submission form today to schedule our 8-hour,
                6-hour, or 4-hour private party boat charter, or our sunset
                cruise private party boat charter!
              </h2>
              <p>
                We are lucky enough to live in a beautiful area that is
                surrounded by white sand beaches and wondrous blue waters, so
                why not take advantage of it? At Tampa Bay Boating Adventures,
                we want you to have the time of your life, so sign up for one of
                our private party boat charters, or our sunset cruise and get
                the party started! All you need to do is{" "}
                <Link to="/contact">fill out our submission form</Link> or{" "}
                <a href="mailto:ahoy@tampabayboatingadventures.com">
                  shoot us an email
                </a>{" "}
                and we’ll get right back to you as soon as possible. Once you
                take a private party boat charter with us, you’ll definitely
                want to come back for more. Keep us in mind when planning
                bachelor/bachelorette parties, birthdays, spring break
                debauchery, and more!
              </p>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default ServicesPage;
