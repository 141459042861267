import { Link } from 'gatsby'
import * as React from 'react'
import { btn } from '../../styles/components/buttons.module.scss'

const SixHourBtn = () => {
  return (
    <Link to="/services/6-hour-private-party-boat-charter" className={btn}>View Package</Link>
  )
}

export default SixHourBtn