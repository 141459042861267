import { Link } from 'gatsby'
import * as React from 'react'
import { btn } from '../../styles/components/buttons.module.scss'

const SunsetHourBtn = () => {
  return (
    <Link to="/services/sunset-private-party-boat-charter" className={btn}>View Package</Link>
  )
}

export default SunsetHourBtn