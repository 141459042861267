import { Link } from 'gatsby'
import * as React from 'react'
import { btn } from '../../styles/components/buttons.module.scss'

const WaveRaveBuyBtn = () => {
  return (
    <a href="https://fareharbor.com/embeds/book/tampabayboatingadventures/items/484511/calendar/2024/07/?full-items=yes&back=https://tampabayboatingadventures.com/services/4-hour-private-party-boat-charter&flow=792230&g4=yes" className={btn}>Book Online</a>
  )
}

export default WaveRaveBuyBtn