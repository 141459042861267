import { Link } from 'gatsby'
import * as React from 'react'
import { btn } from '../../styles/components/buttons.module.scss'

const ExclusiveIslandOvernightBtn = () => {
  return (
    <Link to="/services/exclusive-island-overnight-party" className={btn}>View Package</Link>
  )
}

export default ExclusiveIslandOvernightBtn;